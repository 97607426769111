<template>
	<ul class="menu-nav">
		<router-link
			to="/dashboard"
			v-slot="{ href, navigate, isActive, isExactActive }"
		>
			<li
				aria-haspopup="true"
				data-menu-toggle="hover"
				class="menu-item"
				:class="[
					isActive && 'menu-item-active',
					isExactActive && 'menu-item-active'
				]"
			>
				<a :href="href" class="menu-link" @click="navigate">
					<i class="menu-icon flaticon2-architecture-and-city"></i>
					<span class="menu-text">Dashboard</span>
				</a>
			</li>
		</router-link>

		<router-link
			to="/builder"
			v-slot="{ href, navigate, isActive, isExactActive }"
		>
			<li
				aria-haspopup="true"
				data-menu-toggle="hover"
				class="menu-item"
				:class="[
					isActive && 'menu-item-active',
					isExactActive && 'menu-item-active'
				]"
			>
				<a :href="href" class="menu-link" @click="navigate">
					<i class="menu-icon fas fa-suitcase"></i>
					<span class="menu-text">Jobs</span>
				</a>
			</li>
		</router-link>

		<router-link
			to="/builder"
			v-slot="{ href, navigate, isActive, isExactActive }"
		>
			<li
				aria-haspopup="true"
				data-menu-toggle="hover"
				class="menu-item"
				:class="[
					isActive && 'menu-item-active',
					isExactActive && 'menu-item-active'
				]"
			>
				<a :href="href" class="menu-link" @click="navigate">
					<i class="menu-icon flaticon-file-2"></i>
					<span class="menu-text">Documents</span>
				</a>
			</li>
		</router-link>

		<router-link
			to="/builder"
			v-slot="{ href, navigate, isActive, isExactActive }"
		>
			<li
				aria-haspopup="true"
				data-menu-toggle="hover"
				class="menu-item"
				:class="[
					isActive && 'menu-item-active',
					isExactActive && 'menu-item-active'
				]"
			>
				<a :href="href" class="menu-link" @click="navigate">
					<i class="menu-icon fas fa-credit-card"></i>
					<span class="menu-text">Payments</span>
				</a>
			</li>
		</router-link>

		<router-link
			to="/builder"
			v-slot="{ href, navigate, isActive, isExactActive }"
		>
			<li
				aria-haspopup="true"
				data-menu-toggle="hover"
				class="menu-item"
				:class="[
					isActive && 'menu-item-active',
					isExactActive && 'menu-item-active'
				]"
			>
				<a :href="href" class="menu-link" @click="navigate">
					<i class="menu-icon flaticon-support"></i>
					<span class="menu-text">Support Tickets</span>
				</a>
			</li>
		</router-link>
	</ul>
</template>

<script>
export default {
	name: "KTMenu",
	methods: {
		hasActiveChildren(match) {
			return this.$route["path"].indexOf(match) !== -1;
		}
	}
};
</script>
