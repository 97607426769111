var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
				isActive && 'menu-item-active',
				isExactActive && 'menu-item-active'
			],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon2-architecture-and-city"}),_c('span',{staticClass:"menu-text"},[_vm._v("Dashboard")])])])]}}])}),_c('router-link',{attrs:{"to":"/builder"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
			var href = ref.href;
			var navigate = ref.navigate;
			var isActive = ref.isActive;
			var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
				isActive && 'menu-item-active',
				isExactActive && 'menu-item-active'
			],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-suitcase"}),_c('span',{staticClass:"menu-text"},[_vm._v("Jobs")])])])]}}])}),_c('router-link',{attrs:{"to":"/builder"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
			var href = ref.href;
			var navigate = ref.navigate;
			var isActive = ref.isActive;
			var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
				isActive && 'menu-item-active',
				isExactActive && 'menu-item-active'
			],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-file-2"}),_c('span',{staticClass:"menu-text"},[_vm._v("Documents")])])])]}}])}),_c('router-link',{attrs:{"to":"/builder"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
			var href = ref.href;
			var navigate = ref.navigate;
			var isActive = ref.isActive;
			var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
				isActive && 'menu-item-active',
				isExactActive && 'menu-item-active'
			],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon fas fa-credit-card"}),_c('span',{staticClass:"menu-text"},[_vm._v("Payments")])])])]}}])}),_c('router-link',{attrs:{"to":"/builder"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
			var href = ref.href;
			var navigate = ref.navigate;
			var isActive = ref.isActive;
			var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
				isActive && 'menu-item-active',
				isExactActive && 'menu-item-active'
			],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-icon flaticon-support"}),_c('span',{staticClass:"menu-text"},[_vm._v("Support Tickets")])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }